import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { FileText } from 'lucide-react';

export function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Kullanım Koşulları | KGS Otomasyon</title>
        <meta name="description" content="KGS Otomasyon web sitesi ve hizmetleri kullanım koşulları. Hizmet şartları ve yasal bilgiler." />
        <link rel="canonical" href="https://kgsotomasyon.com/kullanim-kosullari" />
      </Helmet>

      <Header />
      
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="flex items-center mb-8">
              <FileText className="w-8 h-8 text-blue-400 mr-3" />
              <h1 className="text-3xl font-bold text-white">Kullanım Koşulları</h1>
            </div>

            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <div className="prose prose-invert max-w-none">
                <p>
                  KGS Otomasyon web sitesini kullanarak aşağıdaki kullanım koşullarını kabul etmiş sayılırsınız.
                </p>

                <h2>Hizmet Şartları</h2>
                <p>
                  Web sitemiz üzerinden sunduğumuz hizmetler, endüstriyel otomasyon ve PLC programlama 
                  alanındaki uzmanlığımızı yansıtmaktadır. Hizmetlerimizi kullanırken aşağıdaki şartları 
                  kabul etmiş olursunuz:
                </p>
                <ul>
                  <li>Verilen bilgilerin doğruluğu ve güncelliği</li>
                  <li>Hizmet kalitesi ve profesyonel standartlar</li>
                  <li>Müşteri memnuniyeti ve geri bildirim süreci</li>
                  <li>Ödeme ve fiyatlandırma politikaları</li>
                </ul>

                <h2>Fikri Mülkiyet</h2>
                <p>
                  Web sitemizdeki tüm içerik, logolar, görseller ve yazılımlar KGS Otomasyon'un fikri 
                  mülkiyetidir. Bu içeriklerin izinsiz kullanımı, kopyalanması veya dağıtılması yasaktır.
                </p>

                <h2>Sorumluluk Reddi</h2>
                <p>
                  Web sitemizdeki bilgiler genel bilgilendirme amaçlıdır. Spesifik projelere yönelik 
                  teknik detaylar için doğrudan bizimle iletişime geçmenizi öneririz.
                </p>

                <h2>Değişiklikler</h2>
                <p>
                  KGS Otomasyon, bu kullanım koşullarını önceden haber vermeksizin değiştirme hakkını 
                  saklı tutar. Güncel koşullar için web sitemizi düzenli olarak kontrol etmenizi öneririz.
                </p>

                <h2>İletişim</h2>
                <p>
                  Kullanım koşullarımız hakkında sorularınız için info@kgs.com.tr adresinden bizimle 
                  iletişime geçebilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}