import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Shield } from 'lucide-react';

export function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Gizlilik Politikası | KGS Otomasyon</title>
        <meta name="description" content="KGS Otomasyon gizlilik politikası. Kişisel verilerinizin korunması ve kullanımı hakkında bilgiler." />
        <link rel="canonical" href="https://kgsotomasyon.com/gizlilik-politikasi" />
      </Helmet>

      <Header />
      
      <main className="pt-32 pb-20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="flex items-center mb-8">
              <Shield className="w-8 h-8 text-blue-400 mr-3" />
              <h1 className="text-3xl font-bold text-white">Gizlilik Politikası</h1>
            </div>

            <div className="bg-white/5 backdrop-blur-sm rounded-lg p-8">
              <div className="prose prose-invert max-w-none">
                <p>
                  KGS Otomasyon olarak, kişisel verilerinizin güvenliği konusunda büyük hassasiyet gösteriyoruz. 
                  Bu gizlilik politikası, web sitemizi ziyaret ettiğinizde ve hizmetlerimizi kullandığınızda 
                  kişisel verilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu açıklamaktadır.
                </p>

                <h2>Kişisel Verilerin Toplanması</h2>
                <p>
                  Web sitemizi ziyaret ettiğinizde ve iletişim formlarımızı doldurduğunuzda aşağıdaki bilgileri 
                  toplayabiliriz:
                </p>
                <ul>
                  <li>Ad ve soyadınız</li>
                  <li>E-posta adresiniz</li>
                  <li>Telefon numaranız</li>
                  <li>Şirket bilgileriniz</li>
                  <li>IP adresiniz ve tarayıcı bilgileriniz</li>
                </ul>

                <h2>Verilerin Kullanımı</h2>
                <p>
                  Topladığımız kişisel verileri aşağıdaki amaçlar için kullanıyoruz:
                </p>
                <ul>
                  <li>Size hizmetlerimiz hakkında bilgi vermek</li>
                  <li>Taleplerinize yanıt vermek</li>
                  <li>Hizmet kalitemizi iyileştirmek</li>
                  <li>Yasal yükümlülüklerimizi yerine getirmek</li>
                </ul>

                <h2>Verilerin Korunması</h2>
                <p>
                  Kişisel verilerinizi korumak için endüstri standardı güvenlik önlemleri kullanıyoruz. 
                  Verileriniz şifreleme teknolojileri ile korunmakta ve sadece yetkili personel tarafından 
                  erişilebilmektedir.
                </p>

                <h2>Çerezler</h2>
                <p>
                  Web sitemizde çerezler kullanılmaktadır. Çerezler, web sitemizi nasıl kullandığınızı 
                  anlamamıza ve hizmetlerimizi iyileştirmemize yardımcı olmaktadır.
                </p>

                <h2>İletişim</h2>
                <p>
                  Gizlilik politikamız hakkında sorularınız için info@kgs.com.tr adresinden bizimle 
                  iletişime geçebilirsiniz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}