import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { postsApi } from '../lib/api/posts';
import { PostCard } from './ui/PostCard';

export function BlogSection() {
  const { data: posts = [] } = useQuery({
    queryKey: ['latestPosts'],
    queryFn: postsApi.getLatest,
    staleTime: 1000 * 60 * 5 // 5 dakika
  });

  if (!posts || posts.length === 0) return null;

  return (
    <div className="py-20 bg-gradient-to-b from-gray-900 to-blue-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-white text-center mb-12">Son Blog Yazıları</h2>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </div>
        
        <div className="text-center mt-8">
          <a 
            href="https://kgsotomasyon.com/blog"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
          >
            Tüm Yazıları Gör
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M5 12h14M12 5l7 7-7 7"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}