import React from 'react';
import { Camera, Cpu, Settings } from 'lucide-react';
import { ServiceCard } from './ServiceCard';
import { MatrixRain } from './animations/MatrixRain';

const services = [
  {
    title: "PLC Programlama",
    description: "Kocaeli'nin önde gelen Weidmuller ve SICK çözüm ortağı olarak endüstriyel otomasyon çözümleri sunuyoruz.",
    icon: Cpu,
    codeSnippet: `// PLC Control Program
PROGRAM MainControl
VAR
  Conveyor AT %Q0.0: BOOL;
  Sensor1 AT %I0.0: BOOL;
  Timer1: TON;
END_VAR

IF Sensor1 THEN
  Timer1(IN := TRUE, PT := T#2s);
  IF Timer1.Q THEN
    Conveyor := TRUE;
  END_IF;
END_IF`
  },
  {
    title: "Endüstriyel Otomasyon",
    description: "Kocaeli'de endüstriyel otomasyon sistemleri kurulumu ve MURR komponentleri ile tam entegre çözümler.",
    icon: Settings,
    codeSnippet: `// Industrial Automation
class AutomationSystem {
  async initialize() {
    await this.connectPLC();
    await this.startMonitoring();
    this.setupSafety();
    
    console.log("System Ready");
  }

  setupSafety() {
    this.emergencyStop = true;
    this.safetyCircuit.enable();
  }
}`
  },
  {
    title: "Görüntü İşleme",
    description: "Endüstriyel görüntü işleme ve kalite kontrol sistemleri ile üretim süreçlerinizi optimize edin.",
    icon: Camera,
    codeSnippet: `0x89 0x50 0x4E 0x47 0x0D 0x0A 0x1A 0x0A
0x00 0x00 0x00 0x0D 0x49 0x48 0x44 0x52
0xFF 0xD8 0xFF 0xE0 0x00 0x10 0x4A 0x46
// Image Processing
def process_image(frame):
    # Convert to grayscale
    gray = cv2.cvtColor(frame, cv2.COLOR_BGR2GRAY)
    # Apply threshold
    _, binary = cv2.threshold(gray, 127, 255, cv2.THRESH_BINARY)
    return binary`
  }
];

export function ServicesSection() {
  return (
    <div className="relative py-12 bg-gradient-to-b from-gray-900 to-blue-900">
      <div className="container mx-auto px-4 relative z-10">
        <div className="grid md:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              icon={service.icon}
              codeSnippet={service.codeSnippet}
            />
          ))}
        </div>
      </div>
      <MatrixRain />
    </div>
  );
}