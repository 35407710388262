import { createBrowserRouter } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { ActivitiesPage } from './pages/ActivitiesPage';
import { ApplicationsPage } from './pages/ApplicationsPage';
import { MaintenancePage } from './pages/MaintenancePage';
import { ReferencesPage } from './pages/ReferencesPage';
import { ContactPage } from './pages/ContactPage';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/hakkimizda',
    element: <AboutPage />,
  },
  {
    path: '/faliyetlerimiz',
    element: <ActivitiesPage />,
  },
  {
    path: '/uygulamalar',
    element: <ApplicationsPage />,
  },
  {
    path: '/tamir-bakim',
    element: <MaintenancePage />,
  },
  {
    path: '/referanslar',
    element: <ReferencesPage />,
  },
  {
    path: '/iletisim',
    element: <ContactPage />,
  },
  {
    path: '/gizlilik-politikasi',
    element: <PrivacyPolicy />,
  },
  {
    path: '/kullanim-kosullari',
    element: <TermsOfService />,
  }
]);