import React from 'react';
import { 
  CircuitBoard, 
  Cpu, 
  Wrench,
  Phone, 
  Mail, 
  MapPin, 
  Facebook, 
  Linkedin, 
  Youtube,
  FileText,
  Clock,
  Flag
} from 'lucide-react';

// ... rest of the imports remain the same ...

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-gray-900 pt-16 pb-8 overflow-hidden">
      {/* Previous footer content remains the same until the bottom bar */}
      
      {/* Bottom Bar */}
      <div className="mt-12 pt-8 border-t border-gray-800">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="flex items-center space-x-2">
            <p className="text-center text-gray-400">
              © {currentYear} KGS Otomasyon. Tüm hakları saklıdır.
            </p>
            <Flag className="w-5 h-5 text-red-500" title="Türk Bayrağı" />
          </div>
          <nav className="flex flex-wrap justify-center gap-6">
            <a 
              href="https://kgsotomasyon.com/blog/"
              target="_blank"
              rel="noopener noreferrer" 
              className="text-gray-400 hover:text-blue-500 transition-colors"
            >
              Blog
            </a>
            <a 
              href="/gizlilik-politikasi" 
              className="text-gray-400 hover:text-blue-500 transition-colors"
            >
              Gizlilik Politikası
            </a>
            <a 
              href="/kullanim-kosullari" 
              className="text-gray-400 hover:text-blue-500 transition-colors"
            >
              Kullanım Koşulları
            </a>
          </nav>
        </div>
      </div>

      {/* SEO Optimization - Hidden Text */}
      <div className="sr-only">
        <h2>KGS Otomasyon - Kocaeli Endüstriyel Otomasyon Çözümleri</h2>
        <p>
          Kocaeli'nin lider endüstriyel otomasyon firması. PLC programlama, SCADA sistemleri,
          endüstriyel bakım ve Siemens çözüm ortağı. İzmit, Gebze, Darıca, Çayırova, Dilovası
          bölgelerinde hizmet vermekteyiz.
        </p>
        <p>
          Endüstriyel otomasyon, PLC programlama, SCADA sistemleri, HMI panel tamiri,
          endüstriyel PC tamiri, Siemens servis, otomasyon projeleri, endüstriyel bakım
        </p>
      </div>
    </footer>
  );
}